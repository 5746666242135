import React, { useState, useEffect } from "react";
import DetailsSubmenu from "../../common/DetailsSubmenu";
import HeaderDashboard from "../../common/HeaderDashboard";
import RecordingDetailsSidebar from "../../common/RecordingDetailsSidebar";
import Svg from "../../common/Svg";
import { useParams } from "react-router-dom";
import { useUiContext } from "../../../contexts/UiContext";
import { baseUrl } from "../../../constants/common";
import NotificationList from "./components/NotificationList";

export default function Notifications({ route }) {
  const [notification, setNotification] = useState();
  const [recording_uuid, setRecordingUuid] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const { device_uuid } = useParams();
  const { apiRequest } = useUiContext();
  const [recordingDetails, setRecordingDetails] = useState([]);
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    if (device_uuid) {
      getNotification(device_uuid);
    }
  }, [device_uuid]);

  const getNotification = async (device_uuid) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
      "get",
      `api/1/recordings`,
      {},
      headers,
      true,
      { success: "", error: "" },
      { device_uuid: device_uuid, page: 1 }
    )
      .then((res) => {
        if (res.data?.results) setNotification(res.data.results);
        else console.warn(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleIdData = (recording_uuid) => {
    setRecordingUuid(recording_uuid);
  };

  const getTokens = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", `api_auth/1/auth_token`, {}, headers, false)
      .then((res) => {
        if (res.data?.authtoken) {
          // noinspection JSUnresolvedReference
          setAuthToken(res.data);
          return res.data;
        } else {
          console.warn(res.data);
          return authToken;
        }
      })
      .catch((error) => {
        console.error(error);
        return authToken;
      });
  };

  const getRecordingDetails = async (recording_uuid) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
      "get",
      `api/1/recordings/${recording_uuid}`,
      "",
      headers,
      true
    ).then((res) => {
      setRecordingDetails(res.data);
    });
  };
  useEffect(() => {
    let promises = [getTokens()];
    if (recording_uuid !== "") {
      promises.push(getRecordingDetails(recording_uuid));
    }
    return () => Promise.all(promises);
  }, [recording_uuid]);

  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        {/* listing page */}
        <div className="main-page-height devices-page d-flex">
          <RecordingDetailsSidebar route={route} />
          <div className="flex-grow-1">
            <div className="d-flex flex-column">
              <div className="camera-header d-flex align-items-center details-header">
                <div className="dropdown">
                  <button
                    className="btnn"
                    type="button"
                    id="dropdownSorting"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Svg name="camera-active" />
                  </button>
                </div>
                <div className="flex-grow-1 available-heading">
                  <h2>{deviceName}</h2>
                </div>
              </div>
              <div className="flex-grow-1 details-view">
                <DetailsSubmenu route={route} />
                <div className="notification-view-page">
                  <div className="row gx-0">
                    <div className="col-xl-6">
                      <NotificationList
                        notifications={notification}
                        handleIdData={(uuid) =>
                            handleIdData(uuid)
                        }
                        handleDeviceNameChange={(name) => setDeviceName(name)}
                      />
                    </div>
                    <div className="col-xl-6 d-none d-xl-block">
                      <div className="video-wrap">
                        {recordingDetails.snapshot_file_exists ? (
                          <div className="video-section">
                            {recording_uuid !== "" && (
                              <video
                                width="1850"
                                height="700"
                                src={`${baseUrl}api/1/recordings/${recording_uuid}/video/?as_attachment=true&authtoken=${authToken?.authtoken?.token}`}
                                controls
                              ></video>
                            )}
                          </div>
                        ) : (
                          <div className="card-camera-body no-subscriptions">
                            {recordingDetails.snapshot_description && (
                              <div className="alert alert-primary">
                                {recordingDetails.snapshot_description}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
