import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../../helper/theme";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const CustomPagination = ({ page, totalPages, onPageChange }) => {

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={2} alignItems="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => onPageChange(value)}
          showFirstButton
          showLastButton
          color="greenFi"
        />
      </Stack>
    </ThemeProvider>
  );
};

export default CustomPagination;
