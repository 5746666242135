import React from 'react';
import Button from '@mui/material/Button';

const CustomButton = ({ text, color, onClick, className }) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      className={className}
      sx={{
        borderColor: color,
        color: color,
        borderRadius: '50px', // Fully rounded
        textTransform: 'uppercase', // Uppercase text
        padding: '8px 24px', // Adjust padding
        fontWeight: 'bold', // Bold text
        '&:hover': {
          borderColor: color,
          backgroundColor: color, // Add transparency (e.g., rgba equivalent)
          color: '#fff'
        },
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
