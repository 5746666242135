import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ onSearch, className }) => {
  return (
    <TextField
      className={className}
      variant="outlined"
      placeholder="Find your Camera"
      size="small"
      sx={{
        bgcolor: "#1e1e1e",
        color: "#ffffff",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#444444",
          },
          "&:hover fieldset": {
            borderColor: "#666666",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#888888",
          },
        },
        "& .MuiInputBase-input": {
          color: "#ffffff",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "#888888" }} />
          </InputAdornment>
        ),
      }}
      onChange={(e) => onSearch && onSearch(e.target.value)} // Handle search
    />
  );
};

export default SearchBar;
