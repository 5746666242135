// useCaseDeviceList.js

import { useState, useEffect } from "react";
import { useUiContext } from "../../../contexts/UiContext";
import { getAuthHeaders } from "../../../helper/utils";

const useCaseDeviceList = () => {
  const { apiRequest } = useUiContext();
  const [sortBy, setSortBy] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [groupedDeviceList, setGroupedDeviceList] = useState({});

  const getDeviceList = async (currentPage = page) => {
    const headers = getAuthHeaders();
    const res = await apiRequest(
      "get",
      `api/1/devices/?ordering=${sortBy}&page=${currentPage}`,
      "",
      headers,
      false
    );

    const data = res.data.results;
     // Group data by group_uuid and use group name
     const groupedData = data.reduce((groups, device) => {
      (device.groups || []).forEach((group) => {
        const groupKey = group.group_uuid || "Ungrouped"; // Use group_uuid as the key
        const groupName = group.name || "Ungrouped"; // Use group.name for the name
        if (!groups[groupKey]) {
          groups[groupKey] = { name: groupName, devices: [] };
        }
        groups[groupKey].devices.push(device);
      });
      return groups;
    }, {});

    // Merge with existing grouped data if not the first page
    setGroupedDeviceList((prevState) => {
      if (currentPage === 1) return groupedData;

      // Merge new data with existing groups
      const updatedGroups = { ...prevState };
      Object.entries(groupedData).forEach(([groupKey, groupData]) => {
        if (!updatedGroups[groupKey]) {
          updatedGroups[groupKey] = { name: groupData.name, devices: [] };
        }
        updatedGroups[groupKey].devices = [
          ...updatedGroups[groupKey].devices,
          ...groupData.devices,
        ];
      });
      return updatedGroups;
    });

    setTotalPage(res.data.page_count);
  };

  return {
    groupedDeviceList,
    sortBy,
    setSortBy,
    page,
    setPage,
    totalPage,
    getDeviceList,
  };
};

export default useCaseDeviceList;
