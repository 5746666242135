import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Svg from "./Svg";
import UserIcon from "../../assets/images/user-profile.jpg";
import VpsLogo from "../../assets/images/vsp_logo.png";

import { useNavigate } from "react-router-dom";

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import axios from "axios";
import { ThemeContext, themes } from "../../contexts/ThemeContext";
import ToggleDark from "../common/toggleDark";
import { useUiContext } from "../../contexts/UiContext";
import {
  ENTERPRISE_ADMIN,
  LIST_VIEW,
  MULTI_SITE_ADMIN,
  SINGLE_SITE_ADMIN,
  SITE_VIEW,
  baseUrl,
} from "../../constants/common";
// import RefreshButton from "./RefreshButton";
import {
  isUserLoggedIn,
  logoutUser,
  setNavbarDropdown,
  getLocal,
  checkUserRoutePermission,
} from "../../helper/common";
import { useRouteContext } from "../../component/PrivateRoutes";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function HeaderDashboard({ changeView }) {
  let role = getLocal("role");
  let userRole = getLocal("userRole");
  const localName = getLocal("name"); // FIXME
  let name = localName ? localName.trim() : ""; // FIXME
  const {
    apiRequest,
    setNoOfColumns,
    setNoOfColumnsGroupCameras,
    refreshData,
    refreshCount,
    setSearchFilter,
    searchFilter,
  } = useUiContext();
  let navigate = useNavigate();
  const { route } = useRouteContext();
  let help = localStorage.getItem("help");
  let terms = localStorage.getItem("terms");
  let privacy = localStorage.getItem("privacy");

  const signout = () => {
    console.log("Logout");
    localStorage.removeItem("filter");
    logoutUser();
    navigate(`/`);
    axios
      .get(baseUrl + "api_auth/1/logout")
      .then((_) => {
        navigate(`/`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let localdarkMode = localStorage.getItem("darkmode");
  localdarkMode = !(localdarkMode && localdarkMode === "false");
  const [darkMode, setDarkMode] = React.useState(localdarkMode);
  const [isLeftSidebarOpen, setisLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setisRightSidebarOpen] = useState(false);
  const [searchBar, togglesearch] = useState(false);
  const [middleHeader, togglemiddleHeader] = useState(true);
  const [activeRouteName, setActiveRouteName] = useState("Camera");
  const [defaultViewApiData, setdefaultViewApiData] = useState([]);
  const [defaultView, setdefaultView] = useState(LIST_VIEW);
  const params = useLocation();

  useEffect(() => {
    setNavbarDropdown(params.pathname, setActiveRouteName);
    const expirationTime = localStorage.getItem("token");
    if (!expirationTime || expirationTime === "") {
      localStorage.removeItem("token");
      signout();
      window.location.href = "/login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.pathname]);
  useEffect(() => {
    setMiddleheader();
    const expirationTime = localStorage.getItem("token");
    if (!expirationTime || expirationTime === "") {
      localStorage.removeItem("token");
      signout();
      window.location.href = "/login";
    }
  });
  const setMiddleheader = () => {
    const ignorePaths = [
      "/cameras/notifications",
      "/customer/groups",
      "/distributor",
      "/recordings/camera-view/",
      "/reseller/",
      "cameras/details/",
      "cameras/live-camera/",
    ];
    ignorePaths.map((path) => {
      const ignored = params.pathname.includes(path);
      if (ignored) togglemiddleHeader(false);
      return ignored;
    });
  };
  const selectDarkMode = (mode, theme) => {
    setDarkMode(mode);
    localStorage.setItem("darkmode", mode);
    theme(mode ? themes.dark : themes.light);
  };

  const handleResponsiveColumns = (noOfColumns) => {
    if (params.pathname.includes("/group/details")) {
      setNoOfColumnsGroupCameras(noOfColumns);
    }
    if (params.pathname.includes("/cameras")) {
      setNoOfColumns(noOfColumns);
    }
  };

  let typingTimer;
  const typingDelay = (val) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(setSearchFilter, 1000, val);
  };
  const homepageRoute =
    role === "customer" || role === "customerIsAdmin"
      ? "cameras"
      : "account-details";
  const hasMainpermissions =
    checkUserRoutePermission(role, `/${route}/cameras`) ||
    checkUserRoutePermission(role, `/${route}/recordings`) ||
    checkUserRoutePermission(role, `/${route}/groups`);
  const isAdmin = localStorage.getItem("isadmin") === "true";

  /* jshint ignore:start */

  const getApiData = async () => {
    let header = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    let key = "reactapp";
    let params = { key: key, timeStamp: new Date().getTime() };
    return await apiRequest(
      "get",
      "api/1/my-api-data/",
      "",
      header,
      false,
      { showToast: false },
      params
    )
      .then((res) => {
        let response = res.data.results;
        if (response.length > 0) {
          setdefaultViewApiData(response);
          let view = response[0].json.defaultView;
          setdefaultView(view);
          changeView(view);
        } else {
          setdefaultView(LIST_VIEW);
          changeView(LIST_VIEW);
          let key = "reactapp";
          let body = {
            json: {
              defaultView: defaultView,
            },
            key: key,
          };
          postApiCalled(header, body);
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  useEffect(() => {
    getApiData();
  }, []);

  const changeDefaultView = async (checked) => {
    let defaultViewChecked = checked ? SITE_VIEW : LIST_VIEW;
    setdefaultView(defaultViewChecked);
    defaultViewApi(defaultViewChecked);
  };

  const defaultViewApi = async (value) => {
    let header = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    let key = "reactapp";
    let body = {
      json: {
        defaultView: value,
      },
      key: key,
    };
    if (defaultViewApiData.length > 0) {
      let api_data_uuid = defaultViewApiData[0].api_data_uuid;
      patchApiCalled(value, header, api_data_uuid);
    } else {
      postApiCalled(header, body);
    }
  };

  const postApiCalled = async (header, body) => {
    return await apiRequest("post", `api/1/my-api-data/`, body, header, false, {
      success: "Default view Changed.",
      error: "Default view not changed",
      showToast: true,
    })
      .then((res) => {
        getApiData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const patchApiCalled = async (defaultViewChecked, header, api_data_uuid) => {
    let body = {
      json: {
        defaultView: defaultViewChecked,
      },
      key: defaultViewApiData[0].key,
    };
    return await apiRequest(
      "patch",
      `api/1/my-api-data/${api_data_uuid}/`,
      body,
      header,
      false,
      {
        success: "Default view Changed.",
        error: "Default view not changed",
        showToast: true,
      }
    )
      .then((res) => {
        getApiData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {isUserLoggedIn() && (
        <>
          <div
            onClick={() => setisLeftSidebarOpen(false)}
            className={`body-overlay ${
              hasMainpermissions
                ? isLeftSidebarOpen
                  ? "block"
                  : "hidden"
                : "hidden"
            }`}
          />

          <div
            onClick={() => setisRightSidebarOpen(false)}
            className={`body-overlay right-overlay ${
              isRightSidebarOpen ? "block" : "hidden"
            }`}
          />

          <div className="header site-header d-lg-flex d-none">
            <div className="left-wrap d-flex align-items-center">
              <div className="logo-wrap d-flex align-items-center">
                {checkUserRoutePermission(
                  role,
                  `/${route}/${homepageRoute}`
                ) && (
                  <Link
                    to={`/${route}/${homepageRoute}`}
                    onClick={() => {
                      setSearchFilter("");
                      localStorage.removeItem("filter");
                    }}
                    className="logo"
                  >
                    <Svg name="dashboard-logo" />
                  </Link>
                )}

                <button
                  className="camera-dropdown btn-menu"
                  onClick={() => {
                    hasMainpermissions &&
                      setisLeftSidebarOpen(!isLeftSidebarOpen);
                  }}
                >
                  {" "}
                  {activeRouteName}
                </button>
              </div>
              {middleHeader && (
                <div className="add-device-wrap d-flex align-items-center">
                  {params.pathname !== "/customer/groups" && (
                    <>
                      {isAdmin
                        ? checkUserRoutePermission(
                            role,
                            `/${route}/cameras/add-new-device`
                          ) && (
                            <Link
                              to={`/${route}/cameras/add-new-device`}
                              onClick={() => localStorage.removeItem("filter")}
                              className="add-device btn btn-bordered btn-rounded d-none"
                            >
                              <Svg name="plus" /> Add device
                            </Link>
                          )
                        : null}
                      {middleHeader && (
                        <button
                          id="refresh"
                          className="refresh ml-2"
                          onClick={() => {
                            refreshData(refreshCount + 1);
                          }}
                        >
                          <Svg name="refresh" /> Refresh
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>

            <div
              className="display-wrap d-flex align-items-center justify-content-center flex-grow-1 v-hidden"
              style={middleHeader ? { display: "none" } : {}}
            >
              {middleHeader && (
                <>
                  <div className="d-flex align-items-center justify-content-center me-3">
                    <div className="title">Display</div>
                    <div className="display-inputs d-flex align-items-center">
                      <div
                        className="form-checkbox"
                        onClick={() => handleResponsiveColumns(1)}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="display-icon"
                          id="display-icon1"
                        />
                        <label
                          className="form-check-label d-flex align-items-center justify-content-center"
                          htmlFor="display-icon1"
                        >
                          <Svg name="grid-1" />
                        </label>
                      </div>
                      <div
                        className="form-checkbox"
                        onClick={() => handleResponsiveColumns(2)}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="display-icon"
                          id="display-icon2"
                        />
                        <label
                          className="form-check-label d-flex align-items-center justify-content-center"
                          htmlFor="display-icon2"
                        >
                          <Svg name="grid-2" />
                        </label>
                      </div>
                      <div
                        className="form-checkbox"
                        onClick={() => handleResponsiveColumns(3)}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="display-icon"
                          id="display-icon3"
                        />
                        <label
                          className="form-check-label d-flex align-items-center justify-content-center"
                          htmlFor="display-icon3"
                        >
                          <Svg name="grid-3" />
                        </label>
                      </div>
                      <div
                        className="form-checkbox"
                        onClick={() => handleResponsiveColumns(4)}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="display-icon"
                          id="display-icon4"
                          defaultChecked
                        />
                        <label
                          className="form-check-label d-flex align-items-center justify-content-center"
                          htmlFor="display-icon4"
                        >
                          <Svg name="grid-4" />
                        </label>
                      </div>
                    </div>
                  </div>
                  {params.pathname === "/customer/cameras" && (
                    <div className="d-flex align-items-center justify-content-center list-view-site-view">
                      <button
                        className={
                          defaultView === "LIST_VIEW"
                            ? "active list-btn"
                            : "list-btn"
                        }
                        onClick={() => {
                          setdefaultView(LIST_VIEW);
                          defaultViewApi(LIST_VIEW);
                        }}
                      >
                        List View
                      </button>
                      <button
                        className={
                          defaultView === "SITE_VIEW"
                            ? "active list-btn"
                            : "list-btn"
                        }
                        onClick={() => {
                          setdefaultView(SITE_VIEW);
                          defaultViewApi(SITE_VIEW);
                        }}
                      >
                        Site View
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="search-profile-wrap d-flex align-items-center justify-content-end">
              {middleHeader && (
                <div className={`search-wrap ${searchBar ? "active" : ""}`}>
                  {" "}
                  {/* add class active on click of search button to expand the search box*/}
                  <div className="d-flex justify-content-end v-hidden">
                    <input
                      className="form-control"
                      type="search"
                      onChange={(e) => typingDelay(e.target.value)}
                      placeholder="Find your camera"
                      aria-label="Search"
                    />
                    <button
                      className="btn"
                      onClick={() => togglesearch(!searchBar)}
                    >
                      <Svg name="search-icon" />
                    </button>
                  </div>
                </div>
              )}
              <div className="vps-logo d-flex align-items-center mx-2">
                <Link
                  to="https://www.vspsolutions.com.au/"
                  target="_blank"
                  className="logo logo-axis"
                >
                  <img
                    src={VpsLogo}
                    alt="vps-logo"
                    className="img-fluid"
                    style={{ width: "120px" }}
                  />
                </Link>
              </div>
              <div className="profile-wrap">
                <button
                  className="profile-btn"
                  onClick={() => setisRightSidebarOpen(!isRightSidebarOpen)}
                >
                  <img src={UserIcon} alt="User Name" />
                </button>
              </div>
            </div>
          </div>

          <div
            className="header site-header d-flex mobile-header d-lg-none justify-content-between"
            onClick={() => {
              setSearchFilter("");
              localStorage.removeItem("filter");
            }}
          >
            <div className="left-wrap d-flex align-items-center">
              <div className="logo-wrap d-flex align-items-center">
                <Link to="/" className="logo">
                  <Svg name="dashboard-logo" />
                </Link>
              </div>
            </div>
            <div className="add-device-wrap d-flex align-items-center">
              {[ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(
                userRole
              ) && (
                <Link
                  to={`/${route}/cameras/add-new-device`}
                  className="add-device btn btn-bordered btn-rounded"
                >
                  <Svg name="plus" /> Add device
                </Link>
              )}
            </div>

            <div className="search-profile-wrap d-flex align-items-center justify-content-end">
              {middleHeader && (
                <div className={`search-wrap ${searchBar ? "active" : ""}`}>
                  {" "}
                  {/* add class active on click of search button to expand the search box*/}
                  <div className="d-flex justify-content-end">
                    <input
                      className="form-control"
                      type="search"
                      onChange={(e) => typingDelay(e.target.value)}
                      placeholder="Find your camera"
                      aria-label="Search"
                    />
                    <button
                      className="btn"
                      onClick={() => togglesearch(!searchBar)}
                    >
                      <Svg name="search-icon" />
                    </button>
                  </div>
                </div>
              )}
              <div className="profile-wrap">
                <button
                  className={`sideb-toggle ${isRightSidebarOpen ? "open" : ""}`}
                  onClick={() => setisRightSidebarOpen(!isRightSidebarOpen)}
                >
                  <span className="hamburger hamburger-1" />
                  <span className="hamburger hamburger-2" />
                  <span className="hamburger hamburger-3" />
                </button>
              </div>
            </div>
          </div>

          {hasMainpermissions && (
            <div
              className="fixed-footer d-lg-none d-flex justify-content-between"
              onClick={() => {
                setSearchFilter("");
                localStorage.removeItem("filter");
              }}
            >
              <div className="side-navigation-panel">
                {checkUserRoutePermission(role, "/customer/cameras") && (
                  <NavLink
                    to="/customer/cameras"
                    className={
                      activeRouteName === "Camera"
                        ? `side-navigation selected`
                        : "side-navigation"
                    }
                  >
                    <span className="icon camera" />
                    <span className="text">Cameras</span>
                  </NavLink>
                )}
              </div>
              <div className="side-navigation-panel">
                {checkUserRoutePermission(role, "/customer/recordings") && (
                  <NavLink
                    to="/customer/recordings"
                    onClick={() => {
                      localStorage.removeItem("filter");
                    }}
                    className={
                      activeRouteName === "Recordings"
                        ? `side-navigation selected`
                        : "side-navigation"
                    }
                  >
                    <span className="icon recordings" />
                    <span className="text">Recordings</span>
                  </NavLink>
                )}
              </div>
              <div className="side-navigation-panel">
                {checkUserRoutePermission(role, "/customer/groups") && (
                  <NavLink
                    to="/customer/groups"
                    className={
                      activeRouteName === "Groups"
                        ? `side-navigation selected`
                        : "side-navigation"
                    }
                  >
                    <span className="icon groups" />
                    <span className="text">Groups</span>
                  </NavLink>
                )}
              </div>
            </div>
          )}

          {/* Left Sidebar */}
          <div
            className={`left-navigation-menu transition ease-out transform ${
              isLeftSidebarOpen
                ? "ease-out translate-x-0"
                : "ease-in translate-x-full"
            }`}
          >
            <div
              className="side-navigation-panel"
              onClick={() => {
                setSearchFilter("");
                localStorage.removeItem("filter");
              }}
            >
              {checkUserRoutePermission(role, "/customer/cameras") && (
                <NavLink
                  to="/customer/cameras"
                  className={
                    activeRouteName === "Camera"
                      ? `side-navigation-panel-select-option d-flex align-items-center side-navigation-panel-select-option-selected`
                      : "side-navigation-panel-select-option d-flex align-items-center"
                  }
                >
                  <span className="icon camera" />
                  <span className="side-navigation-panel-select-option-text">
                    Cameras
                  </span>
                </NavLink>
              )}
            </div>
            <div className="side-navigation-panel">
              {checkUserRoutePermission(role, "/customer/recordings") && (
                <NavLink
                  onClick={() => {
                    localStorage.removeItem("filter");
                  }}
                  to="/customer/recordings"
                  className={
                    activeRouteName === "Recordings"
                      ? `side-navigation-panel-select-option d-flex align-items-center side-navigation-panel-select-option-selected`
                      : "side-navigation-panel-select-option d-flex align-items-center"
                  }
                >
                  <span className="icon recordings" />
                  <span className="side-navigation-panel-select-option-text">
                    Recordings
                  </span>
                </NavLink>
              )}
            </div>
            <div className="side-navigation-panel">
              {checkUserRoutePermission(role, "/customer/groups") && (
                <NavLink
                  to="/customer/groups"
                  className={
                    activeRouteName === "Groups"
                      ? `side-navigation-panel-select-option d-flex align-items-center side-navigation-panel-select-option-selected`
                      : "side-navigation-panel-select-option d-flex align-items-center"
                  }
                >
                  <span className="icon groups" />
                  <span className="side-navigation-panel-select-option-text">
                    Groups
                  </span>
                </NavLink>
              )}
            </div>
          </div>

          <div
            className={`right-navigation-menu transition ease-out transform ${
              isRightSidebarOpen
                ? "ease-out translate-right-0"
                : "ease-in translate-right-full"
            }`}
          >
            <div className="account-top-wrap flex-grow-1">
              <div className="vps-logo d-flex align-items-center mx-2  d-lg-none">
                <Link
                  to="https://www.vspsolutions.com.au/"
                  target="_blank"
                  className="logo logo-axis"
                >
                  <img
                    src={VpsLogo}
                    alt="vps-logo"
                    className="img-fluid"
                    style={{ width: "80px" }}
                  />
                </Link>
              </div>
              <div className="account-top-scroll">
                <div className="account-wrap">
                  <p>Select account</p>
                  <div className="account-wrapper">
                    <ul>
                      <li className="d-flex align-items-center">
                        <div className="profile-wrap">
                          <img src={UserIcon} alt="User Name" />
                        </div>
                        <div className="profile-name flex-grow-1">
                          {name && name !== "" ? name : "Personal account"}
                        </div>
                        <div className="status-wrap">
                          <Svg name="profile-check" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="account-wrap d-flex align-items-center">
                  <div className="title flex-grow-1">Dark theme</div>
                  <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                      <ToggleDark
                        darkMode={darkMode}
                        toggleDark={() => {
                          selectDarkMode(!darkMode, changeTheme);
                        }}
                      />
                    )}
                  </ThemeContext.Consumer>
                </div>

                <div className="account-wrap d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h4>Default View</h4>
                    <div className="title">Camera List / Site View</div>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="default-view"
                      checked={defaultView === "LIST_VIEW" ? false : true}
                      onChange={(e) => {
                        const val = e.target.checked;
                        changeDefaultView(val);
                      }}
                    />
                    <span className="slider round" />
                  </label>
                </div>

                {checkUserRoutePermission(
                  role,
                  `/${route}/profile-settings`
                ) && (
                  <Link
                    className="account-wrap d-flex"
                    to={`/${route}/profile-settings`}
                  >
                    <div className="title flex-grow-1">Settings</div>
                    <span className="setting-icon"></span>
                  </Link>
                )}
                <div className="account-wrap d-flex">
                  <div className="title flex-grow-1">
                    <a href={help} rel="noreferrer" target="_blank">
                      Help center
                    </a>
                  </div>
                  <span className="help-icon"></span>
                </div>
                <div className="signout text-center">
                  <button
                    className="signout-btn d-inline-flex align-items-center "
                    onClick={signout}
                  >
                    <Svg name="signout-btn" /> Sign Out
                  </button>
                </div>
              </div>
            </div>
            <div className="menu-bottom">
              <ul className="menu-link d-flex flex-wrap">
                <li>
                  <a href={terms} rel="noreferrer" target="_blank">
                    T&Cs
                  </a>
                </li>
                <li>
                  <a href={privacy} rel="noreferrer" target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
              <div className="text-wrap">
                © 2022 EYEfi Pty Ltd. All rights reserved.
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
  /* jshint ignore:end */
}
