import React, { useState } from 'react';
import { Input, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = ({ label = "Password", value, onChange, sx = {} }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="d-flex mb__20">
      <label
        htmlFor="password-input"
        className="col-form-label text-end"
      >
        {label}
      </label>
      <div className="flex-grow-1">
        <Input
          id="password-input"
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          className="form-control"
          sx={{ flexGrow: 1, ...sx }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </div>
  );
};

export default PasswordInput;
