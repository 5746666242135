import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const LiveStatusButton = ({variant, color, text}) => {
  return (
    <Button
      variant={variant}
      sx={{
        position: "absolute",
        top: 10,
        left: 10,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "#fff",
        padding: "5px 10px",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjust hover effect
        },
      }}
      startIcon={
        <Box
          sx={{
            width: 8,
            height: 8,
            bgcolor: color,
            borderRadius: "50%",
          }}
        />
      }
    >
      {text}
    </Button>
  );
};

export default LiveStatusButton;
