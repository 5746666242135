export const customerAdminPaths = [
    '/customer/cameras',
    '/customer/cameras/add-new-device',
    '/customer/cameras/details/:device_uuid',
    '/customer/cameras/live-camera/:device_uuid',
    '/customer/cameras/notifications/:device_uuid',
    '/customer/groups',
    '/customer/group/details/:group_uuid',
    '/customer/help-center',
    '/customer/report-problem',
    '/privacy-policy',
    '/terms',
    '/customer/profile-settings',
    '/customer/account-details',
    '/customer/change-password',
    '/customer/sites',
    '/customer/sites/create',
    '/customer/sites/edit',
    '/customer/sites/add-site-map',
    '/customer/cameras/sites/map-view/:address_uuid',
    '/customer/billable-subscriptions',
    '/customer/devices-and-storage',
    '/customer/users-and-permissions',
    '/customer/users-and-permissions/create',
    '/customer/resellers-system-integrators',
    '/customer/reports-and-analytics',
    '/customer/recordings',
    '/customer/recordings/camera-view/:recording_uuid',
    '/customer/recordings/details/',
    '/customer/recordings/live-camera',
    '/customer/end-subscriptions',
    '/customer/sites/:address_uuid/site',
    '/customer/sites/:address_uuid/site/:site_uuid',
    '/customer/users-and-permissions/edit/:username',
    '/customer/users-and-permissions/resendWelcome',
    '/customer/camera-list',
]

export const customerPaths = [
    '/customer/cameras',
    '/customer/profile-settings',
    '/customer/change-password',
    '/customer/account-details',
    '/customer/sites',
    '/customer/sites/edit',
    '/customer/devices-and-storage',
    '/customer/cameras/details/:device_uuid',
    '/customer/cameras/live-camera/:device_uuid',
    '/customer/groups',
    '/customer/group/details/:group_uuid',
    '/customer/recordings',
    '/customer/recordings/camera-view/:recording_uuid',
    '/customer/customer/notifications/:device_uuid',
    '/customer/help-center',
    '/customer/report-problem',
    '/privacy-policy',
    '/terms',
    '/customer/cameras/sites/map-view/:address_uuid',
    '/customer/users-and-permissions',
    '/customer/sites/:address_uuid/site',
    '/customer/users-and-permissions/create',
    '/customer/users-and-permissions/edit/:username',
    '/customer/sites/:address_uuid/site/:site_uuid',
    '/customer/camera-list',
]


export const sellerAdminPaths = [
    '/reseller/account-details',
    '/reseller/billable-subscriptions',
    '/reseller/end-subscriptions',
    '/reseller/unassigned-subscriptions-summary',
    '/reseller/unassigned-subscriptions-summary/assign',    
    '/reseller/devices-and-storage',
    '/reseller/users-and-permissions',
    '/reseller/users-and-permissions/create',
    '/reseller/resellers-system-integrators',
    '/reseller/reports-and-analytics',
    '/reseller/user-list-table',
    '/reseller/create-reseller',
    '/reseller/users-and-permissions/edit/:username',
    '/reseller/users-and-permissions/resendWelcome'

];
export const sellerPaths = [
    '/reseller/account-details',
    '/reseller/billable-subscriptions',
    '/reseller/end-subscriptions',
    '/reseller/unassigned-subscriptions-summary',
    '/reseller/devices-and-storage',
    '/reseller/users-and-permissions',
    '/reseller/users-and-permissions/create',
    '/reseller/reports-and-analytics',
    '/reseller/end-subscriptions'
];
export const distributorAdminPaths = [
    '/distributor/account-details',
    '/distributor/billable-subscriptions',
    '/distributor/unassigned-subscriptions-summary',
    '/distributor/unassigned-subscriptions-summary/assign',
    '/distributor/unassigned-subscriptions-summary/create',
    '/distributor/users-and-permissions',
    '/distributor/users-and-permissions/create',
    '/distributor/devices-and-storage',
    '/distributor/resellers-system-integrators',
    '/distributor/reports-and-analytics',
    '/distributor/end-subscriptions',
    '/distributor/create-reseller',
    // '/distributor/edit-reseller/:seller_id',
    '/distributor/users-and-permissions/edit/:username',
    '/distributor/users-and-permissions/resendWelcome'

];

export const distributorPaths = [
    '/distributor/account-details',
    '/distributor/billable-subscriptions',
    '/distributor/unassigned-subscriptions-summary',
    '/distributor/devices-and-storage',
    '/distributor/resellers-system-integrators',
    '/distributor/reports-and-analytics',
];