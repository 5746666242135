import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment-timezone";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#333",
  color: "#fff",
  boxShadow: "0px 2px 5px rgba(0,0,0,0.5)",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(59, 180, 74, 0.5)",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  textTransform: "none",
  fontWeight: "bold",
  transition: "background-color 0.3s ease",
  "&:hover": {
    color: "rgba(59, 180, 74, 1)",
    backgroundColor: "transparent",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#333",
  marginBottom: "16px",
  padding: "2rem 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const NotificationList = ({ notifications = [], handleDeviceNameChange, handleIdData }) => {
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    if (notifications.length > 0 && notifications[0].device?.name) {
      handleDeviceNameChange(notifications[0].device.name);
    }
  }, [notifications]);

  const handleAcknowledgeAll = () => {
    const unacknowledgedCount = notifications.filter((notif) => !notif.acknowledged).length;
    console.log(`Acknowledge All - ${unacknowledgedCount} notifications`);
  };

  const handleCardClick = (uuid) => {
    setActiveCard(uuid);
    if (handleIdData) {
      handleIdData(uuid);
    }
  };

  if (notifications.length === 0) {
    return <Typography variant="h6"
    sx={{
      height: '100vh',
      display: 'flex',
      padding: '1rem'
    }}>No Notifications</Typography>;
  }

  return (
    <div>
      <StyledTypography variant="h6">
        NOTIFICATION
        <StyledButton size="small" onClick={handleAcknowledgeAll}>
          Acknowledge All ({notifications.filter((notif) => !notif.acknowledged).length})
        </StyledButton>
      </StyledTypography>
      {notifications.map((notification, index) => (
        <div key={index}>
          {(index === 0 ||
            moment(notification.acknowledged_time).format("DD/MM") !==
              moment(notifications[index - 1].acknowledged_time).format("DD/MM")) && (
            <Typography
              variant="subtitle2"
              style={{ color: "#aaa", marginTop: 16, marginBottom: 8, padding: "4px 10px" }}
            >
              {moment(notification.acknowledged_time).format("MMMM D")}
            </Typography>
          )}
          <StyledCard
            onClick={() => handleCardClick(notification.recording_uuid)}
            style={{
              backgroundColor: activeCard === notification.recording_uuid ? "rgba(59, 180, 74, 0.5)" : "#333",
              boxShadow: activeCard === notification.recording_uuid ? "0 4px 8px rgba(0,0,0,0.1)" : "none",
            }}
          >
            <CardContent style={{ paddingBottom: 8 }}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                {notification.event_id || "Unknown Event"}
              </Typography>
              <Typography variant="body2" style={{ color: "#ccc" }}>
                {notification.recording_description
                  ? moment(notification.recording_description.split(" - ")[0], "YYYY-MM-DD HH:mm:ss").format("HH:mm")
                  : "Unknown Time"}{" "}
                | {notification.device?.name || "Unknown Device"} has detected{" "}
                {notification.event_trigger || "an event"}
              </Typography>
              <Typography variant="body2" style={{ color: "#999" }}>
                {notification.device?.groups && notification.device.groups.length > 0
                  ? notification.device.groups.map((group) => group.name).join(", ")
                  : "No groups"}
              </Typography>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: notification.acknowledged ? "lightgreen" : "red",
                      marginRight: 1,
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      color: notification.acknowledged ? "lightgreen" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {notification.acknowledged ? "Acknowledged" : "Acknowledge"}
                  </Typography>
                </Box>
              </div>
            </CardContent>
          </StyledCard>
        </div>
      ))}
    </div>
  );
};

export default NotificationList;
