export const toUpperCaseText = (text) => {
    return text.toUpperCase();
}

export const MAC_DEFAULT = "123456789123"

export const getAuthHeaders = () => {
    return {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
  };

export const truncateString = (str, length) => {
    if (str.length > length) {
        return str.substring(0, length) + "...";
    }
    return str;
}

