import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const AddDeviceButton = ({ route,to }) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        padding: "10px 16px",
        borderRadius: "20px",
        backgroundColor: "#3bb44a",
        color: "#ffffff",
        fontWeight: "bold",
        textTransform: "none",
      }}
    >
      <AddIcon />
      Add Device
    </Link>
  );
};

export default AddDeviceButton;
