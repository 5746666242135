import React from 'react'
import EyeFiLogo from '../../assets/images/eyefi_logo.png'
import AxisLogo from '../../assets/images/axis_color_logo.png'
import VpsLogo from '../../assets/images/vsp_logo.png'
import { Link } from 'react-router-dom'

export default function HeaderAuthentication() {
    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: "body.dark-theme, body{background-color: #fff;}" }} />
            <div className="header auth-header d-flex justify-content-between">
                <Link to="/" className='logo logo-eyefi'>
                    <img src={EyeFiLogo} alt="EyeFi" className='img-fluid' />
                </Link>
                <Link to="https://www.vspsolutions.com.au/" target="_blank" className='logo logo-axis'>
                    <img src={VpsLogo} alt="vps-logo" className='img-fluid' />
                </Link>
            </div>
        </>
    )
}
