import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    greenFi: {
      main: "#3bb44a", // The green color for the selected page background
    },
  },
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            color: "#aeaeae", // Default text color for all pages
          },
          "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "#3bb44a", // Green background for selected page
            color: "#ffffff", // White text color for selected page
            "&:hover": {
              backgroundColor: "#34a844", // Darker green for hover
            },
          },
        },
      },
    },
  },
});
