import React, {useState, useEffect} from "react";
import Svg from "../../common/Svg";
import HeaderDashboard from "../../common/HeaderDashboard";
import {useUiContext} from "../../../contexts/UiContext";
import {useNavigate, useParams} from "react-router-dom";
import {baseUrl} from "../../../constants/common";
import {Link} from "react-router-dom";
import {getLocal, checkUserRoutePermission} from "../../../helper/common";
import moment from "moment-timezone";

export default function CameraView({route}) {
  let role = getLocal("role");
  const {apiRequest} = useUiContext();
  const [recordingDetails, setRecordingDetails] = useState([]);
  const {recording_uuid} = useParams();
  // const [video, setvideo] = useState("");
  const [authToken, setAuthToken] = useState("");
  const navigate = useNavigate();
  const getRecordingDetails = async (recording_uuid) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
        "get",
        `api/1/recordings/${recording_uuid}/`,
        "",
        headers,
        true
    ).then(res => {
      setRecordingDetails(res.data);
    });
  };

  useEffect(() => {
    getTokens().then(_ => {
      getRecordingDetails(recording_uuid).then(_ => {
      });
    });
  }, [recording_uuid]);


  const getTokens = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
        "get",
        `api_auth/1/auth_token`,
        {},
        headers,
        false
    ).then(res => {
      if (res.data?.authtoken) {
        // noinspection JSUnresolvedReference
        setAuthToken(res.data);
        return res.data;
      } else {
        console.warn(res.data);
        return authToken;
      }
    }).catch((error) => {
      console.error(error);
      return authToken;
    });
  };
  const timeZone = localStorage.getItem("tz");

  return (
      <div className="d-flex flex-column site-main-wrapper grid-page">
        <HeaderDashboard/>
        <div className="flex-grow-1 main-wrapper">
          {/* listing page */}

          <div className="main-page-height devices-page d-flex">
            {/* <RecordingSidebar /> */}
            <div className="flex-grow-1">
              <div className="d-flex flex-column">
                <div className="flex-grow-1 camera-view">
                  {/*If Grid is Camera - 3x2*/}

                  <div className="camera-row">
                    <div className="card-camera">
                      <div className="card-header d-flex">
                        <button
                            className="btn btn-link p-0 me-2"
                            onClick={() => navigate(-1)}
                        >
                          <Svg name="back-arrow-big"/>
                        </button>
                        <div className="camera-status">
                          <Svg name="camera-active"/>
                        </div>
                        <div className="camera-details flex-grow-1 d-flex align-items-center">
                          <div className="flex-grow-1 camera-name">
                            {recordingDetails.device?.name}
                          </div>
                          <div className="dropdown notification-dropown ">
                            <button
                                className={`btn-notif ${
                                    recordingDetails.recordings_unacknowledged_count >
                                    0
                                        ? "active"
                                        : ""
                                }`}
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                              <Svg name="notification"/>
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuButton1"
                            >
                              <div className="notif-header">
                                <a href="#">Mark all as read</a>
                              </div>
                              <ul>
                                <li>
                                  <a className="dropdown-item" href="#">
                                  <span className="me-2">
                                    {recordingDetails.acknowledged_time}
                                  </span>
                                    <span>{recordingDetails.device_uuid}</span>
                                  </a>
                                </li>
                              </ul>
                              {checkUserRoutePermission(
                                  role,
                                  `/${route}/cameras/notifications/:device_uuid`
                              ) && (
                                  <div className="notification-footer">
                                    <Link
                                        to={`/${route}/cameras/notifications/${recordingDetails.device_uuid}`}
                                    >
                                      <button className="btn btn-primary btn-rounded">
                                        View All
                                      </button>
                                    </Link>
                                  </div>
                              )}
                            </div>
                          </div>

                          {checkUserRoutePermission(
                              role,
                              `/${route}/cameras/details/:device_uuid`
                          ) && (
                              <Link
                                  to={`/${route}/cameras/details/${recordingDetails.device?.device_uuid}`}
                                  className="btn-notif ms-1 details-btn"
                              >
                                <Svg name="setting"/>
                              </Link>
                          )}
                        </div>
                      </div>
                      <div className="card-subhead">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 text-wrapper">
                            <div className="d-lg-flex flex-wrap mata-details">
                              {/* <div className="d-flex title">
                                                            <div className="icon">
                                                                <Svg name="scan-icon" />
                                                            </div>
                                                            <p className='m-0'>{recordingDetails.device_uuid}</p>
                                                        </div> */}
                              <div className="d-flex title">
                                <div className="icon">
                                  <Svg name="play-icon"/>
                                </div>
                                <p className="m-0">
                                  {moment.tz(recordingDetails.start_time, timeZone).format("DD/MM/YYYY HH:mm a")}
                                </p>
                              </div>

                              <div className="d-flex title">
                                <div className="icon">
                                  <Svg name="time-icon"/>
                                </div>
                                <p className="m-0">
                                  {recordingDetails.stop_time
                                      ? moment.tz(recordingDetails.stop_time, timeZone).format("DD/MM/YYYY HH:mm a")
                                      : ""}
                                </p>
                              </div>
                              {/* <div className="d-flex title">
                                                                <div className="icon">
                                                                    <Svg name="location-icon" />
                                                                </div>
                                                                <p className='m-0'>Australia/Melbourne</p>
                                                            </div> */}
                            </div>
                          </div>
                          <div className="icon-wrap">
                            <a
                                href={`${baseUrl}api/1/recordings/${recording_uuid}/video/?as_attachment=true&authtoken=${authToken?.authtoken?.token}`}
                                target="_blank" rel="noreferrer"
                            >
                              <Svg name="download-small-icon"/>
                            </a>
                          </div>
                        </div>
                      </div>
                      {recordingDetails.storage_recording_file_exists ? (
                          <div className="card-camera-body">
                            <video
                                width="1850"
                                height="700"
                                src={`${baseUrl}api/1/recordings/${recording_uuid}/video/?as_attachment=true&authtoken=${authToken?.authtoken?.token}`}
                                controls
                            ></video>
                          </div>
                      ) : (
                          <div className="card-camera-body no-subscriptions novideo">
                            <div className="alert alert-primary">
                              {recordingDetails.recording_description}
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
