import { useEffect, useRef } from "react";
import { checkUserRoutePermission, getLocal } from "../../../../helper/common";
import { useRouteContext } from "../../../../component/PrivateRoutes";
import { Link } from "react-router-dom";
import Svg from "../../../common/Svg";

export default function CameraStorageList({
  filter,
  deviceDetails,
  isOpenCameraStorageFullScreenModal,
  closeCameraStoragePopOver,
}) {
  let role = getLocal("role");
  const homepageRoute =
    role === "customer" || role === "customerIsAdmin"
      ? "cameras"
      : "account-details";
  const iframeRef = useRef(null);
  const { route } = useRouteContext();

  useEffect(() => {
    if (isOpenCameraStorageFullScreenModal) {
      // reload();
    }
  }, [isOpenCameraStorageFullScreenModal]);

  return (
    <>
      <div className="modal-header site-header">
        <div className="logo-wrap d-flex align-items-center">
          {checkUserRoutePermission(role, `/${route}/${homepageRoute}`) && (
            <Link
              to={`/${route}/${homepageRoute}`}
              onClick={() => {
                localStorage.removeItem("filter");
              }}
              className="logo"
            >
              <Svg name="dashboard-logo" />
            </Link>
          )}

          <button
            className="camera-dropdown btn-menu"
            onClick={() => {
              closeCameraStoragePopOver();
            }}
          >
            {" "}
            Recordings
          </button>
        </div>
        {deviceDetails && (
          <h5 className="ms-5 modal-title">
            Camera Storage - {deviceDetails.name}
          </h5>
        )}
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeCameraStoragePopOver();
          }}
        ></button>
      </div>
      <div className="modal-body">
        {filter && filter.device_uuid.length > 0 && deviceDetails && (
          <iframe
            ref={iframeRef}
            src={`${deviceDetails?.axis_config_url}camera/index.html#/recordings`}
            title={deviceDetails?.name}
            height={"100%"}
            width={"100%"}
          />
        )}
      </div>
    </>
  );
}
