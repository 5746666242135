import React, { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useUiContext } from "../../contexts/UiContext";
import Svg from "./Svg";
import { getLocal, checkUserRoutePermission } from "../../helper/common";
import useCaseDeviceList from "./services/useDeviceListGrouped";
import { Typography, Box } from "@mui/material";

export default function RecordingDetailsSidebar({
  route,
  clearSelectedSiteData,
  setCameraEventsOptions,
  setSelectedEvents,
  setDeviceName
}) {
  let role = getLocal("role");
  const { device_uuid } = useParams();
  const { setSearchFilter } = useUiContext();
  const {
    groupedDeviceList,
    sortBy,
    setSortBy,
    page,
    setPage,
    totalPage,
    getDeviceList,
  } = useCaseDeviceList();

  let navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    getDeviceList();
  }, [sortBy]);

  const handleScroll = () => {
    if (page <= totalPage) {
      setPage((prePage) => prePage + 1);
      if (page !== 1) {
        getDeviceList(page);
      }
    }
  };

  return (
    <div className="sidebar-theme sidebar-details">
      <div className="camera-header d-flex align-items-center">
        <div className="flex-grow-1 available-heading">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-link p-0"
              onClick={() => {
                navigate(`/customer/cameras`);
                setSearchFilter("");
              }}
            >
              <Svg name="back-arrow-big" />
            </button>
            <div className="dropdown-wrap d-flex align-items-center">
              <label>Sort by -</label>
              <div className="dropdown">
                <button
                  className="btn p-0 dropdown-toggle"
                  type="button"
                  id="dropdownSorting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Name
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownSorting"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setSortBy("name")}
                    >
                      A To Z
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setSortBy("-name")}
                    >
                      Z to A
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-wrapper"
        id="devicelist-wrapper"
        onScroll={handleScroll}
        style={{ overflowY: "scroll", height: "100vh" }}
      >
        {Object.entries(groupedDeviceList).map(([groupKey, groupData]) => (
          <div key={groupKey}>
            <h4>{groupData.name}</h4> {/* Ensure groupData.name exists */}
            {groupData.devices.map((device) => {
              const handleDeviceClick = () => {
                setCameraEventsOptions?.(["All Events"]);
                clearSelectedSiteData?.();
                setSelectedEvents?.([]);
                if (setDeviceName) {
                  setDeviceName(device.name);  // Ensure setDeviceName is defined
                }
              };

              const renderDeviceLink = () => {
                const detailsPermission = checkUserRoutePermission(
                  role,
                  `/${route}/cameras/details/:device_uuid`
                );

                const liveCameraPermission = checkUserRoutePermission(
                  role,
                  `/${route}/cameras/live-camera/:device_uuid`
                );

                if (pathname.includes("details") && detailsPermission) {
                  return (
                    <Link
                      className="text-decoration-none"
                      to={`/${route}/cameras/details/${device.device_uuid}`}
                    >
                      <Typography variant="body2">{device.name}</Typography>
                    </Link>
                  );
                } else if (liveCameraPermission) {
                  return (
                    <Link
                      to={`/${route}/cameras/live-camera/${device.device_uuid}`}
                    >
                      <Typography className="text-decoration-none" variant="body2">{device.name}</Typography>
                    </Link>
                  );
                }
                return <Typography className="text-decoration-none" variant="body2">{device.name}</Typography>;
              };

              return (
                <Box
                  key={device.device_uuid}
                  className={`list-side-camera ${
                    device.device_uuid === device_uuid ? "active" : ""
                  }`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleDeviceClick}
                >
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: device.status === "ok" ? "green" : "red",
                      marginRight: 1,
                    }}
                  />
                  {renderDeviceLink()}
                </Box>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
