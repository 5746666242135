import { useState } from "react";
import { useUiContext } from "../../../../contexts/UiContext";

export const useToken = () => {
  const { apiRequest } = useUiContext();
  const [authToken, setAuthToken] = useState("");

  const getTokens = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    try {
      const response = await apiRequest("get", `api_auth/1/auth_token`, {}, headers, false);
      if (response?.data?.authtoken) {
        setAuthToken(response.data);
        return response.data;
      } else {
        console.warn("Token not found in response:", response.data);
        return authToken;
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      return authToken;
    }
  };

  return { authToken, setAuthToken, getTokens };
};
