import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import { ReactComponent as ShieldActive } from "../components/icon/shieldActive.svg";
import { ReactComponent as ShieldOff } from "../components/icon/shieldOff.svg";
import { ReactComponent as HornOn } from "../components/icon/hornOn.svg";
import { ReactComponent as HornOff } from "../components/icon/hornOff.svg";

const NavigationIcon = ({ position, style, onIconClick, iconsToShow }) => {
  // Define the initial state for each toggleable icon
  const [iconStates, setIconStates] = useState({
    record: false,
    sound: false,
    mic: false,
    shield: false,
    dim: false,
    horn: false,
  });

  // Define the full list of icons
  const allIcons = [
    { on: ShieldActive, off: ShieldOff, stateKey: "shield" },
    { on: HornOn, off: HornOff, stateKey: "horn" },
    { on: FlashlightOnIcon, off: FlashlightOffIcon, stateKey: "dim" },
    { on: MicIcon, off: MicOffIcon, stateKey: "mic" },
    {
      on: RadioButtonCheckedIcon,
      off: RadioButtonUncheckedIcon,
      stateKey: "record",
    },
    { on: VolumeUpIcon, off: VolumeOffIcon, stateKey: "sound" },
    { on: ZoomOutMapIcon, off: null, stateKey: "zoom" }, // Single icon, no toggle
  ];

  // Filter the icons to display based on `iconsToShow`
  const icons = allIcons.filter((icon) =>
    iconsToShow ? iconsToShow.includes(icon.stateKey) : true
  );

  const handleToggle = (stateKey, isOn) => {
    if (stateKey) {
      setIconStates((prevState) => ({
        ...prevState,
        [stateKey]: !prevState[stateKey],
      }));

      // Pass state change to parent through callback
      if (onIconClick) {
        onIconClick(stateKey, !isOn); // Pass the stateKey and the new state
      }
    } else if (onIconClick) {
      // For non-toggleable icons, just notify the parent
      onIconClick("zoom", true);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        gap: 1.5,
        ...position, // Allows dynamic positioning
        ...style, // Adds custom styles
      }}
    >
      {icons.map(({ on: IconOn, off: IconOff, stateKey }, index) => {
        const isOn = stateKey ? iconStates[stateKey] : false;

        return (
          <IconButton
            key={index}
            onClick={() => handleToggle(stateKey, isOn)}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Circular background color
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Hover effect
              },
              borderRadius: "50%", // Makes it circular
              width: 35, // Adjust button size
              height: 35, // Adjust button size
            }}
          >
            {/* Conditionally render the "on" or "off" icon */}
            {stateKey && isOn ? (
              <IconOn sx={{ fontSize: 20 }} />
            ) : IconOff ? (
              <IconOff sx={{ fontSize: 20 }} />
            ) : (
              <IconOn sx={{ fontSize: 20 }} />
            )}
          </IconButton>
        );
      })}
    </Box>
  );
};

export default NavigationIcon;