import React from 'react';
import RefreshButton from '../../common/RefreshButton';// Assuming this is a separate component
import Svg from '../../common/Svg';

export default function GroupHeaders({
  groups,
  activeGroupName,
  groupUuid,
  setGroupUuid,
  setActiveGroupName,
  setSortBy,
  defaultView,
  LIST_VIEW,
}) {
  return (
    <>
      {defaultView === LIST_VIEW && (
        <div className="camera-header d-flex align-items-center justify-content-between">
          {/* Filter List for Large Screens */}
          <ul className="filter-list flex-grow-1 align-items-center d-none d-lg-flex">
            <li key="all" onClick={() => setGroupUuid("")}>
              <button className={groupUuid === "" ? "active" : ""}>All</button>
            </li>
            {groups?.map((group) => (
              <li key={group.group_uuid} onClick={() => setGroupUuid(group.group_uuid)}>
                <button className={groupUuid === group.group_uuid ? "active" : ""}>
                  {group.name}
                </button>
              </li>
            ))}
          </ul>

          {/* Dropdown for Mobile Screens */}
          <div className="d-block d-lg-none">
            <div className="dropdown group-dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownSorting"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {activeGroupName}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownSorting">
                <li key="all" onClick={() => { setActiveGroupName("All"); setGroupUuid(""); }}>
                  <button className="dropdown-item">All</button>
                </li>
                {groups?.map((group) => (
                  <li key={group.group_uuid} onClick={() => { setActiveGroupName(group.name); setGroupUuid(group.group_uuid); }}>
                    <button className="dropdown-item">{group.name}</button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Refresh Button for Mobile Screens */}
          <div className="d-block d-lg-none text-center">
            <RefreshButton />
          </div>

          {/* Sorting Dropdown */}
          <div className="dropdown v-hidden">
            <button
              className="btnn bg-transparent p-0"
              type="button"
              id="dropdownSorting"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Svg name="dropdown-icon" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownSorting">
              <li>
                <span className="dropdown-item" onClick={() => setSortBy("name")}>
                  A To Z
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={() => setSortBy("-name")}>
                  Z to A
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}