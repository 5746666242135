import React from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ControlButton = ({ direction, onClick }) => {
  const icons = {
    up: <ArrowUpwardIcon />,
    down: <ArrowDownwardIcon />,
    left: <ArrowBackIcon />,
    right: <ArrowForwardIcon />,
  };

  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: '#333',
        color: '#fff',
        margin: '5px',
        '&:hover': {
          backgroundColor: '#555',
        },
      }}
    >
      {icons[direction]}
    </IconButton>
  );
};

export default ControlButton;
